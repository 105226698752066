import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

// s4n
import { injectIntl, Link } from 'gatsby-plugin-intl'
import CollectionDataUtils from "./../../utils/Collection/data"
// import '../assets/sass/components/_timeline.scss'
// import '../assets/sass/components/_team.scss'


// @todo: create <Person /> component
import kebabCase from "lodash.kebabcase"

const translation = {
    default: {
        pl: ``,
        en: ``,
        de: ``,
        es: ``,
    },
    international_partners: {
        pl: `Partnerzy międzynarodowi`,
        en: `International partners`,
        de: `Internationale Partner`,
        es: `Socios internacionales`,
    },
    specjalista_ds_biznesu: {
        pl: `Specjalista ds. Biznesu`,
        en: `Business Specialist`,
        de: `Wirtschaftsspezialist`,
        es: `Especialista en Negocios`,
    },
}

const Team = (props) => {
    const { blockId, intl } = props;
    // const t = intl.formatMessage;

    const data = useStaticQuery(graphql`
        query {
            index: allNodePerson (
                sort: {
                    fields: [field_weight]
                    order: ASC
                }
            ) {
                edges {
                    node {
                        id
                        drupal_id
                        drupal_internal__nid
                        internal {
                            type
                        }

                        status
                        title
                        field_position
                        field_social_media_links
                        langcode
                        # path {
                        #     alias
                        #     langcode
                        # }
                        body {
                            summary
                            processed
                        }
                        created

                        field_person_category

                        relationships {
                            
                            # field_image {
                            #     localFile {
                            #         childImageSharp {
                            #             fluid(maxWidth: 400) {
                            #                 ...GatsbyImageSharpFluid
                            #             }
                            #         }
                            #     }
                            # }

                            # @see: https://stackoverflow.com/questions/55032900/gatsby-drupal-8-getting-an-image-when-using-the-media-module-acquia-lightni
                            field_image_media {
                                relationships {
                                    field_media_image {
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 400) {
                                                    # ...GatsbyImageSharpFluid
                                                    # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                                    ...GatsbyImageSharpFluid_withWebp
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    `)

    // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
    const aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, intl.locale);



    let aDataCollectionTranslated_Grouped = {};
    aDataCollectionTranslated.forEach((item, index) => {
// console.log(`item: `, item);
        if (`undefined` === typeof aDataCollectionTranslated_Grouped[item.node.field_person_category]) {
            aDataCollectionTranslated_Grouped[item.node.field_person_category] = [];
        }

        aDataCollectionTranslated_Grouped[item.node.field_person_category].push(item);
    });
// console.log(`aDataCollectionTranslated_Grouped: `, aDataCollectionTranslated_Grouped);
// console.log(`Object.keys(aDataCollectionTranslated_Grouped): `, Object.keys(aDataCollectionTranslated_Grouped));



    return (
        <React.Fragment>

            <section id="fh5co-team" className="fh5co-bg-color" data-section="team">
                <div className="fh5co-team">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-md-12 section-heading _text-center">
                                <h2 className="to-animate"><span>Meet The Team</span></h2>
                                <div className="row">
                                    <div className="col-12 _col-md-offset-2 subtext">
                                        <h3 className="to-animate">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove. </h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        {Object.keys(aDataCollectionTranslated_Grouped).map((display_group, display_group_index) => {
// console.log(`display_group: `, display_group);
                            if (!display_group) {
                                return null;
                            }

                            return (
                                <React.Fragment>
                                    {translation?.[display_group][intl.locale] ? 
                                        <h2 className="mt-5">{translation[display_group][intl.locale]}</h2>
                                        : null
                                    }

                                    <div className="row">
                                        {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
                                        {aDataCollectionTranslated_Grouped[display_group].map(({ node }, index) => {
                                            const person = node;

                                            // console.log(person)
                                            let img = node?.relationships?.field_image_media?.relationships?.field_media_image?.localFile?.childImageSharp?.fluid;

                                            return (
                                                <div key={person.title} className="item col-md-6 col-lg-4 col-xl-3">
                                                    <div className="team-box text-center to-animate-2">
                                                        <div className="user">
                                                            <Link to={`/${kebabCase(person.title)}`}>
                                                                {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}> */}
                                                                    { img ? <Img fluid={ img } alt={`${person.title} - ${person.field_position}`} /> : <i className="fas fa-user"></i> }
                                                                {/* </div> */}
                                                            </Link>
                                                        </div>
                                                        <h3>
                                                            <Link to={`/${kebabCase(person.title)}`}>
                                                                { person.title }
                                                            </Link>
                                                        </h3>
                                                        <p className="position">{ person.field_position }</p>
                                                        {/* <p className="summary" dangerouslySetInnerHTML={{ __html: person.body ? person.body.summary : null }}></p> */}

                                                        <ul className="social-media">
                                                            {person.field_social_media_links[0] && 
                                                                <li><a href={`mailto:${person.field_social_media_links[0]}`} className="email"><i className="fas fa-envelope"></i></a></li>}
                                                            {person.field_social_media_links[1] && 
                                                                <li><a href={person.field_social_media_links[1]} target="_blank" className="facebook"><i className="fab fa-facebook"></i></a></li>}
                                                            {person.field_social_media_links[2] && 
                                                                <li><a href={person.field_social_media_links[2]} target="_blank" className="twitter"><i className="fab fa-twitter"></i></a></li>}
                                                            {person.field_social_media_links[3] && 
                                                                <li><a href={person.field_social_media_links[3]} target="_blank" className="dribbble"><i className="fab fa-instagram"></i></a></li>}
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>
                            )
                        })}

                        
                    </div>
                </div>
            </section>

        </React.Fragment>        
    )
}

export default injectIntl(Team)